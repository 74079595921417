.title {
    line-height: 48px;
    font-weight: 700;
    text-align: center;
    font-size: 32px;
}

.editable h2 { 
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-top: 48px;
    margin-bottom: 24px;
    line-height: 32px;
}

.editable p { 
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
    color: #333;
}